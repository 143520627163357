@tailwind base;
@tailwind utilities;
@tailwind components;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

@layer base {
	html {
		letter-spacing: -0.5px;
		-webkit-font-smoothing: antialiased;
		font-feature-settings: 'kern' !important;
		font-family: 'DM Sans', sans-serif !important;
	}
}

input.defaultCheckbox::before {
	left: 50%;
	opacity: 0; 
	width: 16px;
	height: 16px;
	color: white;
	position: absolute; 
	transform: translate(-50%, 0px);
	content: url(../src/assets/svg/checked.svg); 
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}


.scrollbar-none {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.scrollbar-none::-webkit-scrollbar {
	display: none;
}